import gdpr from "./gdpr";
import tcfv1 from "./gdpr/tcfv1";
import tcfv2 from "./gdpr/tcfv2";
import ccpa from "./ccpa";
import gpp from "./gpp";

const getPolicies = async (iframeWindow: Window) => {
  const tcfv2Promise = tcfv2.createConsentPromise(iframeWindow);
  const tcfv1Promise = tcfv1.createConsentPromise(iframeWindow);
  const ccpaPromise = ccpa.createUspPromise();
  const gppPromise = gpp.createGppPromise();

  await Promise.all([
    tcfv2Promise,
    tcfv1Promise,
    ccpaPromise,
    gppPromise,
  ]);

  const policies = gdpr.getGDPR();
  return {
    ...policies,
    uspString: ccpa.uspString,
    gppString: gpp.gppString,
    gppSID: gpp.gppSID,
  };
}

export default getPolicies;