import { Config, Player, StickyDesktop, StickyMobile } from "@/types";
import { logConsole, throttle } from "@/utils";
export function setStickyMobileTop(player: Player, config: StickyMobile, container: HTMLElement, topWindow: Window) {
  const iframe: HTMLIFrameElement = container.querySelector(".mia-iframe");
  let headerTopDistanceFix = 0;
  if (config.selector) {
    if (config.fixed) {
      headerTopDistanceFix = topWindow.document.querySelector<HTMLElement>(config.selector).offsetHeight;
    } else {
      player.floatingScrollListener = throttle(10, () => {
        const headerBottom = topWindow.document.querySelector(config.selector).getBoundingClientRect().bottom;
        if (headerBottom > 0) {
          headerTopDistanceFix = headerBottom;
        } else {
          headerTopDistanceFix = 0;
        }
        iframe.style.top = `${headerTopDistanceFix}px`;
      });
      topWindow.addEventListener("scroll", player.floatingScrollListener);
    }
  }
  iframe.style.top = `${headerTopDistanceFix}px`;

  const scrollListener = () => {
    const { top } = container.getBoundingClientRect();
    if (top <= headerTopDistanceFix) {
      iframe.classList.add("mia-sticky-top");
      topWindow.removeEventListener("scroll", scrollListener);
    }
  };
  topWindow.addEventListener("scroll", scrollListener);
  logConsole("Sticky top floating mode initialized", [config]);
}

export function setStickyDesktop(config: Config, player: Player, stickyConfig: StickyDesktop, container: HTMLElement) {
  const iframe: HTMLIFrameElement = container.querySelector(".mia-iframe");
  const verticalOffset = `${stickyConfig.verticalOffset || 0}px`;
  const horizontalOffset = `${stickyConfig.horizontalOffset || 0}px`;
  switch (stickyConfig.position) {
    case "top-left":
      iframe.style.top = verticalOffset;
      iframe.style.left = horizontalOffset;
      // transform = "-100%";
      break;
    case "top-right":
      iframe.style.top = verticalOffset;
      iframe.style.right = horizontalOffset;
      // transform = "-100%";
      break;
    case "bottom-left":
      iframe.style.bottom = verticalOffset;
      iframe.style.left = horizontalOffset;
      break;
    case "bottom-right":
    default:
      iframe.style.bottom = verticalOffset;
      iframe.style.right = horizontalOffset;
      break;
  }
  const handleStickyOnScroll = throttle(50, () => {
    const { top } = container.parentElement.getBoundingClientRect();
    // bottom || top limit
    const middlePosition = top + (config.maxHeight / 2);
    if (middlePosition <= 0 || middlePosition >= config.topWindow.document.documentElement.clientHeight) {
      iframe.style.setProperty("max-width", `${stickyConfig.maxWidth || (stickyConfig.maxWidth ? stickyConfig.maxWidth * 16 / 9 : null)}px`, "important");
      if (!iframe.classList.contains("mia-sticky-desktop")) {
        iframe.style.height = "auto";
        iframe.classList.add("mia-sticky-desktop");
      }
    } else {
      iframe.classList.remove("mia-sticky-desktop");
      iframe.style.height = "100%";
      iframe.style.removeProperty("max-width");
    }
    player.jwplayer.resize();
  });
  if (iframe.contentWindow.miaForcedSticky) {
    // calculated the absolute position to get fixed the player when the element's ancestors have a transform property
    player.floatingScrollListener = () => {
      if (stickyConfig.position.includes("top")) {
        iframe.style.top = `${window.scrollY + stickyConfig.verticalOffset}px`;
      } else {
        iframe.style.top = `${window.scrollY + window.innerHeight - iframe.offsetHeight - stickyConfig.verticalOffset}px`;
      }
      handleStickyOnScroll();
    }
  } else {
    player.floatingScrollListener = handleStickyOnScroll;
  }
  config.topWindow.addEventListener("scroll", player.floatingScrollListener);

  logConsole("Sticky desktop floating mode initialized", [
    "\nposition:", stickyConfig.position,
    "\nmaxWidth:", stickyConfig.maxWidth,
    "\nverticalOffset:", verticalOffset,
    "\nhorizontalOffset:", horizontalOffset
  ]);
}