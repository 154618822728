import setupConfigs from "@wrapper/setupConfigs";
import { PLACEMENTS_API_ROOT, DEVICE_SIZES } from "@/constants";

declare global {
  interface Window {
    ampSeen: boolean;
    context: { canonicalUrl?: string };
    _FskOvUrl?: string;
  }
}
type Params = {
  location: string;
  size: string;
  publisherId?: string;
}

const getConfig = async (params: Params) => {
  const response = await fetch(`${PLACEMENTS_API_ROOT}/placements?${new URLSearchParams(params)}`);
  return response.json();
}

const getSizes = () => {
  const [deviceSize] = Object.entries(DEVICE_SIZES).find(([key, size]) => window.screen.width >= size ? key : null);
  return deviceSize || "u"; // "unknown" device size from SpringServe
}

const currentScript = document.currentScript as HTMLScriptElement;
const ids = currentScript.dataset;
if (!ids.publisherId) {
  ids.publisherId = currentScript.src.split('#')[1];
}
window.initMIA = async () => {
  const location = window.top.location.href
    || window.top.document.location.href
    || window.top.document.documentURI
    || window.top.document.URL
    || document.referrer
    || window.context.canonicalUrl
    || window.context?.data.ampSlotIndex
    || window.top.location.origin + window.top.location.pathname + window.top.location.hash
    || window.parent.location.href
    || window.parent?.parent?.location.href
    || window.top.document.title
    || encodeURIComponent(window.top.document.body.outerHTML);
  const config = window.parent.miaTestConfig ? { placements: [window.parent.miaTestConfig] } : await getConfig({
    location,
    ...ids,
    size: getSizes(),
  });
  // Passing data for MIA Debugger
  window.top.miaConfig = {
    placements: config.placements,
    publisherId: ids.publisherId,
  };
  // Passing config for demo page
  window.parent.miaPlacementConfig && window.parent.miaPlacementConfig(config.placements?.[0]);
  setupConfigs(config.placements, ids.publisherId);
}
window.initMIA();
