import initAd from "@wrapper/initAd";
import { Config } from "@/types";
const init = (container: HTMLElement, containerWindow: Window, splitBoxDiv: HTMLElement, config: Config) => {
  container.insertAdjacentHTML("afterbegin", `<div class="wrapper"></div>`);
  if (config.displayOptions.preOpen) {
    let height = `${Math.round(config.maxWidth ? parseInt(config.maxWidth) * (9 / 16) : container.offsetWidth * (9 / 16))}px`;
    // Add custom margin offsets to the height
    const customOffsets = [...config.insertionCSS.matchAll(/margin-(top|bottom):(\d*)(\w*)/g)]
      .reduce((previous, current) => `(${previous} + ${current[2]}${current[3]})`, "0px");
    // Add claim text height
    height = config.displayOptions.claimText ? `calc(32px + ${height} + ${customOffsets})` : `calc(${height} + ${customOffsets})`;
    // Set container approximate height
    container.style.minHeight = height;
    splitBoxDiv.parentElement.style.minHeight = height;
  }
  initAd(
    config,
    containerWindow,
    config.id,
    splitBoxDiv,
  );
}
export default function setupTargets (config: Config, targetConfigs: HTMLElement[]) {
  targetConfigs.forEach((splitBoxDiv) => {
    if (window.ampSeen) {
      const container = document.createElement("div");
      config.topWindow = window.top;
      splitBoxDiv.appendChild(container);
      init(container, window.top, splitBoxDiv, config);
    } else {
      const iframe = document.createElement("iframe");
      iframe.classList.add("mia-iframe");
      iframe.setAttribute("title", "in-article advertisement");
      iframe.addEventListener("load", () => {
        iframe.contentDocument.body.style.overflow = "hidden";
        iframe.contentDocument.body.style.margin = "0";
        init(iframe.contentDocument.body, iframe.contentWindow, splitBoxDiv, config)
      });
      splitBoxDiv.appendChild(iframe);
    }
  });
}