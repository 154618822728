import { ASSETS_PATH, CHROME_RUNTIME_PATH } from "@/constants";
import { Config } from "@/types";
import { logConsole, logError } from "@/utils";

type Params = { [key: string]: string };
type Iframe = HTMLIFrameElement & {
  contentWindow: Window & {
    passbackConfig: PassbackConfig;
  };
}
interface PassbackConfig {
  priority: number;
  fskconf: Config;
  params: [{ selector: string, value: string }];
  options: { [key: string]: string };
  adId: string;
  name: string;
  iframe: Iframe;
  width: string;
  height: string;
  doScale: string;
}

const PASSBACKS_MAP = {
  "AdPlus": "adplus",
  "DFP Banner Script": "dfp-banner",
  "DFP Banner Script - Responsive": "dfp-banner-responsive",
  "Google passback tag - new version": "google-new",
  "Google passback tag": "google",
  "Magnite DV+": "magnite",
  "Standard script":"standard",
  "Teads - inRead": "teads",
}

const getProperty = (property: string | (() => void)) => {
  if (!property) return "";
  if (typeof property === "function") {
    return property();
  }
  return property === "true" || property === "false" 
    ? JSON.parse(property) 
    : property;
}

const handlePassback = (config: Config, container: HTMLElement) => {
  try {
    if (config.miaParams) return;
    let passbackConfig: PassbackConfig;

    const passbackConfigs = config.passbacks?.sort((a: PassbackConfig, b: PassbackConfig) => a.priority - b.priority);
    const initPassback = async () => {
      passbackConfig = passbackConfigs.splice(0, 1)[0];
      passbackConfig.fskconf = config;
      try {
        logConsole("Passback inserted", ["Passback config: ", passbackConfig]);
        await showPassback(passbackConfig, container);
        container.parentElement.style.maxHeight = "none";
        config.tracking("passback_called");
        logConsole("Passback initialized", ["Passback config: ", passbackConfig]);
      } catch (error) {
        logError(error, config);
        if (passbackConfigs.length > 0) initPassback();
      }
    }
    if (passbackConfigs.length === 0) return;
    initPassback();
  } catch (error) {
    logError(error, config);
  }
}

// const passbackByType = async (passbackConfig: PassbackConfig, container: Element) => {
//   switch (passbackConfig.type) {
//     case "PASSBACKS":
//       return new Promise((resolve, reject) => {
//           showPassback(passbackConfig, container).then(() => {
//             resolve(true);
//           }).catch(() => {
//             reject(new Error("Script not loaded correctly"));
//           });
//       });
//       break;
//     case "CUSTOM":
//       passbackConfig.passback(passbackConfig.fskconf, container);
//       break;
//     case "ADX":
//     case "DFP":
//       showGooglePassback(passbackConfig, container);
//       break;
//   }
//   console.log(`mia: %cPassback initialized. Type: ${passbackConfig.type}.`, "color: #4dc97b", "Passback config: ", passbackConfig);
// }

// const showGooglePassback = (passbackConfig: PassbackConfig, container: Element) => {
//   const width = getProperty(passbackConfig.width);
//   const height = getProperty(passbackConfig.height);
//   const path = getProperty(passbackConfig.path);
//   let content = passbackConfig.type === "ADX" ? `
// 			<script type="text/javascript" src="${ADX_PASSBACK_URL}">
// 				googletag.pubads().definePassback("${path}", [${width}, ${height}]).display();
// 			</script>
// 		` : `
// 			<ins
// 				class="adsbygoogle"
// 				style="display: inline-block; width: ${width}px; height: ${height}px" 
// 				data-ad-client="${getProperty("adClient")}" 
// 				data-ad-slot="${getProperty("adSlot")}">
// 			</ins>
// 			<script type="text/javascript" src="${DFP_PASSBACK_URL}"></script>
// 		`;
//   content += "<style type="text/css">body{margin: 0px;}</style>";
//   const { contentWindow } = buildPassbackIframe(container, passbackConfig, content);
//   if (passbackConfig.type === "DFP") {
//     setTimeout(() => {
//       if (!contentWindow.adsbygoogle) {
//         contentWindow.adsbygoogle = [];
//       }
//       contentWindow.adsbygoogle.push({});
//     }, 500);
//   }
// }

const showPassback = async (passbackConfig: PassbackConfig, container: HTMLElement) => {
  const options = passbackConfig.params.reduce((result, { selector, value }) => ({ ...result, [selector]: value }), {});
  passbackConfig.iframe = buildPassbackIframe(container, options);

  if (!passbackConfig.iframe) return;

  passbackConfig.adId = container.id;
  const scriptElement = document.createElement("script");
  scriptElement.src = `${CHROME_RUNTIME_PATH ? CHROME_RUNTIME_PATH : ASSETS_PATH}/passbacks/${PASSBACKS_MAP[passbackConfig.name]}.js`;
  passbackConfig.iframe.contentWindow._MiaAds = window._MiaAds;
  passbackConfig.iframe.contentWindow.passbackConfig = { ...passbackConfig, options };
  passbackConfig.iframe.contentWindow.document.body.appendChild(scriptElement);
  if (window.miaKeyValues) passbackConfig.iframe.contentWindow.miaKeyValues = window.miaKeyValues;
  container.style.display = "none";
  return new Promise((resolve, reject) => {
    scriptElement.onload = () => resolve(true);
    scriptElement.onerror = () => reject(new Error("Passback script not loaded correctly")); 
  });
}

const buildPassbackIframe = (container: HTMLElement, options: Params, content?: string): Iframe => {
  const width = getProperty(options.width);
  const height = getProperty(options.height);
  const doScale = getProperty(options.doScale) ? `transform: scale(${container.offsetWidth / width});` : "";
  const src = content ? `data:text/html;charset=utf-8,${encodeURI(content)}` : "about:blank";
  container.insertAdjacentHTML("afterend", `
		<div 
			id="${container.id}_passback"
			style="display: none; overflow:hidden; text-align: center; padding: 0; height: ${height}px;"
		>
			<iframe
				id="${container.id}_passback_iframe"
				src="${src}"
				style="margin: auto; ${width && `width: ${width}px;`} ${height && `height: ${height}px;`} border-style: none; transform-origin: 0 0; ${doScale}"
			>
			</iframe>
		</div>
	`);

  return <Iframe>container.parentElement.querySelector(`#${container.id}_passback_iframe`);
}

export { buildPassbackIframe, handlePassback as default };

