let uspCB = () => {
  // callback to call when got USP string
};
const callId = Math.random().toString(36).substring(2, 12);
let uspString = '';


function uspHandler(uspData, success, uspCallId) {
  if (success) {
    uspString = uspData.uspString;
  }
  uspCB();
}

window.addEventListener('message', (event) => {
  if (event && event.data && event.data.__uspapiReturn) {
    uspHandler(
      event.data.__uspapiReturn.returnValue,
      event.data.__uspapiReturn.success,
      event.data.__uspapiReturn.callId,
    );
  }
}, false);

const usp = {
  createUspPromise() {
    // CCPA - call USP API
    return new Promise((resolve) => {
      let isCalled = false;
      uspCB = () => {
        isCalled = true;
        resolve(true);
      };
      this.uspApiCall();
      setTimeout(() => {
        if (!isCalled) {
          isCalled = true;
          uspCB();
        }
      }, 100);
    });
  },

  registerCb(cb) {
    uspCB = cb;
  },

  uspApiCall() {
    if (window.__uspapi) {
      // same frame
      window.__uspapi('getUSPData', 1, uspHandler);
    } else {
      // iframe
      window.top.postMessage({
        __uspapiCall: {
          callId,
          command: 'getUSPData',
          version: 1
        }
      }, '*');
    }
  },

  set uspString(str) {
    uspString = str;
  },

  get uspString() {
    return uspString;
  }
}

export default usp;