import gdpr from './index';

let tcfv2CB = () => {
  // callback to call when got GDPR data
};
let callId = 0;

function findTcfFrame(frame) {
  try {
    if (frame.frames['__tcfapiLocator']) {
      return frame;
    }
  } catch (ignore) { }
  if (frame === window.top) {
    return null;
  }
  return findTcfFrame(frame.parent);
}


function tcfv2Handler(tcfResult, success) {
  let gdprStatus = {};
  if (!tcfResult || success === false) {
    gdprStatus = {
      gdpr: 0
    };
  } else {
    gdprStatus.gdpr = tcfResult.gdprApplies ? 1 : 0;
    if (tcfResult.tcString) {
      gdprStatus.gdpr_consent = tcfResult.tcString;
    }
  }
  gdpr.setConsentData(gdprStatus, 2);
  tcfv2CB();
}

window.addEventListener('message', (event) => {
  if (event && event.data && event.data.__tcfapiReturn) {
    tcfv2Handler(
      event.data.__tcfapiReturn.returnValue,
      event.data.__tcfapiReturn.success
    );
  }
}, false);

const tcfv2 = {
  createConsentPromise(iframeWindow: Window) {
    // GDPR - call Consent Manager Provider API
    return new Promise((resolve) => {
      let isCalled = false;
      tcfv2CB = () => {
        isCalled = true;
        resolve(true);
      };
      this.getConsentData();
      setTimeout(() => {
        if (!isCalled) {
          isCalled = true;
          tcfv2CB();
        }
      }, iframeWindow?.miaDelayCMP || 100);
    });
  },

  registerCb(cb) {
    tcfv2CB = cb;
  },

  getConsentData() {
    if (window.top.__tcfapi) {
      // top frame
      window.top.__tcfapi('getTCData', 2, tcfv2Handler);
    } else {
      // find TCFv2 API locator iframe
      const tcfFrame = findTcfFrame(window);
      if (!tcfFrame) {
        return;
      }
      // call locator iframe
      callId += 1;
      tcfFrame.postMessage({
        __tcfapiCall: {
          callId: `iframe:${callId}`,
          command: 'getTCData',
          version: 2
        }
      }, '*');
    }
  }
}

export default tcfv2;