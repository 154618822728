export const CHROME_RUNTIME_PATH = document.currentScript.dataset.miaChromePath;
window.miaChromePath = CHROME_RUNTIME_PATH || "";

export const DEFAULT_PLAYER_URL = CHROME_RUNTIME_PATH ? `${CHROME_RUNTIME_PATH}/jwplayer.js` : process.env.PLAYER_URL;
export const ASSETS_PATH = process.env.ASSETS_PATH;
export const PLACEMENTS_API_ROOT = process.env.PLACEMENTS_API_ROOT;
export const PASSBACKS_AD_PLUS = process.env.PASSBACKS_AD_PLUS;

export const JWPLAYER_KEY = "OGfG5DPP9Fo/X6kEVRjaqcTxeggXXaD6nuP4399JQRRcvivc";
// export const ADX_PASSBACK_URL = "//www.googletagservices.com/tag/js/gpt.js";
// export const DFP_PASSBACK_URL = "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"";

export const CLOSE_ICON = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 409.8 409.8">
  <path d="M229 205L404.5 29.3a17 17 0 10-24.1-24L204.8 180.8 29.1 5.2A17 17 0 005 29.3L180.7 205 5 380.7a17 17 0 0024.1 24.1l175.7-175.7 175.7 175.7a17 17 0 0024.1-24.1L228.9 205z"/>
</svg>`

export const MAGNITE_ICON = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 868.3 784.3"> 
  <path d="M246.1,784.3c0,0-44-640.4-44-724.6h-8.9c4.5,84.2,1.8,724.6,1.8,724.6H0V0h392.5c0,0,38.2,655.3,38.2,739.3
h6.9c0-84,38.2-739.3,38.2-739.3h392.5v784.3H673.3c0,0-2.8-640.4,1.8-724.6h-8.9c0,84.2-44,724.6-44,724.6H246.1z"/>
</svg>`;
export const MAGNITE_ICON_URL = "https://magnite.com";

export const LOCALIZATION = {
  en: {
    thanks: "Thanks for watching",
    more: "Learn more",
  },
  fr: {
    thanks: "Merci de votre attention",
    more: "En savoir plus",
  },
  de: {
    thanks: "Danke fürs zuschauen",
    more: "Erfahren Sie mehr",
  },
  es: {
    thanks: "Gracias por ver",
    more: "Aprende más",
  },
  it: {
    thanks: "Grazie per la visione",
    more: "Saperne di più",
  },
  tr: {
    thanks: "İzlediğiniz için teşekkürler",
    more: "Daha fazla bilgi edin",
  },
  zh: {
    thanks: "感谢您的观看",
    more: "了解更多",
  },
  ja: {
    thanks: "見てくれてありがとう",
    more: "もっと詳しく知る",
  },
  ko: {
    thanks: "시청 해주셔서 감사합니다",
    more: "더 알아보기",
  },
  pl: {
    thanks: "Dzięki za oglądanie",
    more: "Ucz się więcej",
  },
  pt: {
    thanks: "Obrigado por assistir",
    more: "Saber mais",
  },
  sv: {
    thanks: "Tack för att du tittade",
    more: "Läs mer",
  },
  no: {
    thanks: "Takk for at du så på",
    more: "Lære mer",
  },
  da: {
    thanks: "Tak fordi du kiggede med",
    more: "Lær mere",
  },
  fi: {
    thanks: "Kiitos katsomisesta",
    more: "Lue lisää",
  },
  hu: {
    thanks: "Köszönöm a megtekintést",
    more: "Tudj meg többet",
  },
  id: {
    thanks: "Terima kasih telah menonton",
    more: "Belajarlah lagi",
  },
  lt: {
    thanks: "Ačiū, kad žiūrėjote",
    more: "Sužinokite daugiau",
  },
  nl: {
    thanks: "Bedankt voor het kijken",
    more: "Kom meer te weten",
  },
  bg: {
    thanks: "Благодаря за гледането",
    more: "Научете повече",
  },
  cs: {
    thanks: "Díky za sledování",
    more: "Zjistěte více",
  },
  et: {
    thanks: "Tänan vaatamast",
    more: "Lisateavet",
  },
  lv: {
    thanks: "Paldies par skatīšanos",
    more: "Uzzināt vairāk",
  },
  ro: {
    thanks: "Multumesc pentru vizionare",
    more: "Află mai multe",
  },
  el: {
    thanks: "Ευχαριστώ που παρακολουθήσατε",
    more: "Μάθε περισσότερα",
  },
  uk: {
    thanks: "Дякуємо за перегляд",
    more: "Дивитись більше",
  },
}

export const DEVICE_SIZES = {
  xl: 800,
  l: 501,
  m: 350,
  s: 250,
  xs: 0,
}