interface DomLibrary {
  elements: HTMLElement[];
  find: (selector: string) => HTMLElement;
}
export default function DomLibrary (source: string | HTMLElement | HTMLElement[] | Window, currentDocument = document) {
  const instance = Object.create(DomLibrary.prototype);
  if (!source) {
    instance.elements = [];
    return instance;
  }
  if (typeof source === "string") {
    source = source
      .replace(/(nth)\((\d+)(\))/g, (match, nth, counter) => `${nth}-of-type(${counter})`)
      .replace(/(first|last)/g, "$1-of-type");

    const elements = [...currentDocument.querySelectorAll(source)];
    elements.forEach((element, index) => {
      instance[index] = element;
    });
    instance.elements = [...elements];
    instance.length = elements.length;
  } else if (source instanceof HTMLElement || source instanceof Window) {
    instance[0] = source;
    instance.elements = [source];
    instance.length = 1;
  } else {
    source.forEach((element, index) => {
      instance[index] = element;
    });
    instance.elements = [...source];
    instance.length = source.length;
  }
  return instance;
}

DomLibrary.prototype.index = function () {
  if (!this.length) {
    return -1;
  }
  return [...this.elements[0].parentElement.children].indexOf(this.elements[0]);
}
DomLibrary.prototype.text = function () {
  return this.elements[0].textContent;
}
DomLibrary.prototype.find = function (selector: string) {
  return DomLibrary(this.elements.reduce((previous: HTMLElement[], current: HTMLElement) => {
    return [...previous, ...current.querySelectorAll(selector)];
  }, []));
}
DomLibrary.prototype.each = function (callback: (childElement: HTMLElement, index: number) => void) {
  this.elements?.forEach((childElement: HTMLElement, index: number) => {
    callback.call(childElement, index, childElement);
  });
}
DomLibrary.prototype.filter = function (callback: (element: HTMLElement) => boolean) {
  return DomLibrary(this.elements?.filter((element: HTMLElement) => callback.call(element, element)));
}
DomLibrary.prototype.prev = function () {
  return DomLibrary(this.elements.map((element: HTMLElement) => element.previousElementSibling));
}
DomLibrary.prototype.next = function () {
  return DomLibrary(this.elements.map((element: HTMLElement) => element.nextElementSibling));
}
DomLibrary.prototype.children = function (selector: string) {
  return DomLibrary(this.elements.reduce((previous: HTMLElement[], current: HTMLElement) => {
    return [...previous, ...current.querySelectorAll(`:scope > ${selector || "*"}`)];
  }, []));
}
DomLibrary.prototype.parent = function () {
  return DomLibrary(this.elements.map((element: HTMLElement) => element.parentElement));
}
DomLibrary.prototype.attr = function (attributeName: string) {
  return this.elements[0].getAttribute(attributeName);
}
DomLibrary.prototype.after = function (content: string) {
  this.elements[0].insertAdjacentHTML("afterend", content);
  return DomLibrary(this.elements[0].nextElementSibling);
}
DomLibrary.prototype.has = function (selector: string) {
  return DomLibrary(this.elements.filter((element: HTMLElement) => element.querySelector(selector)));
}
DomLibrary.prototype.outerHeight = function () {
  return this.elements[0].offsetHeight;
}
DomLibrary.prototype.height = function () {
  if (this.elements[0] instanceof Window) {
    return this.elements[0].innerHeight;
  }
  return parseFloat(getComputedStyle(this.elements[0]).height.replace("px", ""));
}
DomLibrary.prototype.width = function () {
  if (this.elements[0] instanceof Window) {
    return this.elements[0].innerWidth;
  }
  return parseFloat(getComputedStyle(this.elements[0]).width.replace("px", ""));
}
DomLibrary.prototype.scrollTop = function () {
  return this.elements[0].scrollTop || this.elements[0].pageYOffset;
}
DomLibrary.prototype.data = function (attributeName: string) {
  return this.attr(`data-${attributeName}`);
}
DomLibrary.prototype.hide = function () {
  this.elements.forEach((element: HTMLElement) => {
    element.style.display = "none";
  });
}