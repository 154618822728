function findLocatorFrame(frame) {
  try {
    if (frame.frames['__gppLocator']) {
      return frame;
    }
  } catch (ignore) {
    console.log(ignore);
  }
  if (frame === window.top) {
    return null;
  }
  return findLocatorFrame(frame.parent);
}

window.addEventListener('message', (event) => {
  if (event && event.data && event.data.__gppReturn) {
    if (event.data.__gppReturn.success) {
      gpp.gppString = event.data.__gppReturn.returnValue;
    }
  }
}, false);

const gpp = {
  createGppPromise() {
    return new Promise((resolve) => {
      if (window.__gpp) {
        this.getData(resolve);
      } else {
        const gppFrame = findLocatorFrame(window);
        if (!gppFrame) {
          resolve(true);
        }
        // call locator iframe
        gppFrame.postMessage({
          __gppCall: {
            callId: Math.random().toString(36).substring(2, 12),
            command: 'ping',
            version: 1
          }
        }, '*');
      }
      setTimeout(() => resolve(true), 100);
    });
  },

  getData(resolve) {
    try {
      window.__gpp('ping', (data) => {
        gpp.gppString = data.gppString;
        gpp.gppSID = data.applicableSections.join(',');
        resolve(true);
      });
    } catch {
      resolve(true);
    }
  }
}

export default gpp;