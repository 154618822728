import { Companion, Config, Player } from "@/types";
import { logConsole } from "@/utils";
const getCompanionAttributes = (companion: Element): Companion => ({
  id: companion.id,
  width: companion.getAttribute("width"),
  height: companion.getAttribute("height"),
  click: companion.querySelector("CompanionClickThrough")?.textContent,
  resource: companion.querySelector("StaticResource")?.textContent,
  type: companion.querySelector("StaticResource") ? "static" : companion.querySelector("IFrameResource") ? "iframe" : "HTML",
});
const parseBanners = (response: XMLDocument): Companion[] => {
  const banners = [...response.querySelectorAll(`Extension[type="mia"] Banner`)];
  const companions = [...response.querySelectorAll("Companion")];
  if (banners.length > 0) {
    return banners.map((banner) => {
      const companion = companions.find((item) => item.id === banner.id);
      if (companion) {
        return {
          position: banner.getAttribute("type"),
          tracking: [...banners.find(i => i.id === banner.id).querySelectorAll("Tracking")]?.map((item) => ({ event: item.getAttribute("event"), url: item.textContent })),
          ...getCompanionAttributes(companion),
        };
      }
    });
  } else {
    return companions.map((companion) => ({
      position: "FOOTER",
      tracking: [...companion.querySelectorAll("Tracking")]?.map((item) => ({ event: item.getAttribute("event"), url: item.textContent })),
      ...getCompanionAttributes(companion),
    }))
  }
}
interface BannerInterface {
  companion?: HTMLElement,
  type?: string,
  bannerElement: HTMLElement,
  setMarkupByType: () => void,
  addImageBanner: (clickCallback: () => void, imgLink: string) => void,
  setEligibleCompanion: () => void,
}
const Banner = (config: Config, bannerType: string, companions: Companion[]): BannerInterface => ({
  bannerElement: window.document.createElement("div"),
  setMarkupByType() {
    if (this.companion.type === "static") {
      this.addImageBanner((clickEvent: Event) => {
        clickEvent.stopPropagation();
        window.open(decodeURIComponent(this.companion.click || config.clickThroughUrl));
        this.companion.tracking.forEach(({ event, url }: { event: string, url: string }) => {
          if (event === "click") fetch(url);
        });
      }, this.companion.resource);
      return;
    }

    // if (this.companion.type === "HTML") {
    //   let iframeContent = this.companion.resource;
    //   if (window.location.protocol === "https:") {
    //     iframeContent = iframeContent.replace(/http:/g, "https:");
    //   }
    //   iframeContent = `
    //     <body style="margin: 0; overflow: hidden">
    //       ${iframeContent.replace(/\[CACHEBUSTER\]/g, new Date().getTime().toString())}
    //     </body>`;
    //   this.addMarkupBanner(`data:text/html;charset=utf-8,${encodeURI(iframeContent)}`);
    //   return;
    // }

    // if (this.companion.type === "iframe") {
    //   this.addMarkupBanner(this.companion.resource);
    // }
  },
  addImageBanner(clickCallback, imgLink) {
    this.bannerElement.insertAdjacentHTML("beforeend", `
      <img
        src="${imgLink}"
        style="max-width: 100%; max-height: 100%; cursor: pointer;"
        class="mia-banner"
      />
    `);
    this.bannerElement.addEventListener("click", clickCallback);
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        this.companion?.tracking.forEach(({ event, url }: { event: string, url: string }) => {
          if (event === "impression") fetch(url);
        });
        observer.unobserve(this.bannerElement)
        return
      }
    }, { root: null, threshold: 0.5 });
    observer.observe(this.bannerElement);
  },
  // addMarkupBanner(src: string) {
  //   const iframe = document.createElement("iframe");
  //   iframe.src = src;
  //   iframe.setAttribute("style", `
  //     width: ${this.companion.width}px;
  //     height: ${this.companion.height}px;
  //   `);
  //   iframe.classList.add("mia-banner");
  //   this.bannerElement.appendChild(iframe);
  // },
  setEligibleCompanion() {
    const eligibleByConfig = companions?.filter((companion: Companion) => {
      for (const size of config[`${bannerType}Banner`].companionSizes) {
        const [width, height] = size.split("x");
        if (companion.height && companion.width) {
          if (companion.height == height && companion.width == width) return true;
        } else {
          return false;
        }
      }
    }).sort((a: Companion, b: Companion) => {
      const aDiff = Number(a.width) - config.container.offsetWidth;
      const bDiff = Number(b.width) - config.container.offsetWidth;
      // if companion bigger than the container width, get the closest to the container width
      if (config.isMobile || (aDiff > 0 && bDiff > 0)) {
        return aDiff > bDiff ? 1 : -1;
      // if companions smaller than the container width, get the largest
      } else {
        return aDiff > bDiff ? -1 : 1;
      }
    });
    this.companion = eligibleByConfig?.[0];
  },
});

const setupFooterBanner = (config: Config, companions: Companion[], { document }) => {
  const banner = Banner(config, "footer", companions);
  document.querySelector(".wrapper").appendChild(banner.bannerElement);
  banner.setEligibleCompanion();
  if (!banner.companion) return;
  banner.setMarkupByType();
  banner.bannerElement.classList.add("mia-footer");
  banner.bannerElement.querySelector(".mia-banner").addEventListener("load", () => {
    config.container.style.height = `${document.querySelector(".wrapper").offsetHeight}px`;
  });
  logConsole("Footer banner initialized", ["Banner element: ", banner.bannerElement, "\nCompanion options: ", banner.companion]);
  return banner.companion;
};

const setupLeaveBehindBanner = (config: Config, player: Player, companions: Companion[], { document }) => {
  const banner = Banner(config, "leaveBehind", companions);
  document.querySelector(".jwplayer .jw-wrapper").appendChild(banner.bannerElement);
  banner.bannerElement.classList.add("mia-post-leave-behind-banner");
  banner.setEligibleCompanion();

  if (banner.companion) {
    banner.setMarkupByType();
    banner.type = "COMPANION";
  }
  // House ad leave behind banner recieved in config from Placement server
  const { type, imgUrl, clickThrough, script } = config.leaveBehindBanner;
  if (!banner.type && (script || imgUrl)) {
    banner.type = type.toUpperCase();
    if (banner.type === "IMAGE") {
      banner.addImageBanner(() => {
        window.open(clickThrough);
      }, imgUrl);
    } else if (banner.type === "SCRIPT") {
      const scriptElement = document.createElement("script");
      scriptElement.innerHTML = `window.leaveBehindScript = (config, container) => {${script}}`;
      document.head.appendChild(scriptElement);
      document.defaultView.leaveBehindScript(config, banner.bannerElement);
    }
  }
  
  if (!config.displayOptions.preOpen && !config.displayOptions.keepOpen) {
    banner.bannerElement.querySelector(".mia-banner").addEventListener("load", () => {
      config.updateHeight(banner.bannerElement.offsetHeight);
    });
  }
  if (!banner.type) {
    player.remove();
    return;
  }
  banner.bannerElement.classList.add("visible");
  document.querySelector(".mia-footer")?.remove();
  logConsole(`Post banner initialized. Type: ${banner.type}.`, ["Banner element: ", banner.bannerElement, banner.type === "COMPANION" ? banner.companion : ""]);
}

export { parseBanners, setupFooterBanner, setupLeaveBehindBanner };
