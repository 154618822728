import { LOCALIZATION } from "@/constants";
import getPolicies from "@/policies/getPolicies";
import { Config } from "@/types";
import userSync from "@/userSync";
import { logConsole, tracking } from "@/utils";
import fskAdsBridge from "@wrapper/bridge";
import DomLibrary from "@wrapper/domLibrary";
import AutoInsertion, { getTargets } from "@wrapper/insertion";
import setupTargets from "@wrapper/setupTargets";
// window._MiaAds.appendStyle(".fsk-always-on-box-background {background-color: #000000;}");

window.miaLib = DomLibrary;
window.miaLib.trim = function (source: HTMLElement | string) {
	return source instanceof HTMLElement ? source.textContent.trim() : source.trim();
}
window.miaLib.each = function (source: HTMLElement[], callback: () => void) {
	if (!source) return;
	source.forEach((item, index: number) => {
		callback.call(item, index, item);
	});
}
window._MiaAds = fskAdsBridge;
if (window.parent) window.parent._MiaAds = fskAdsBridge;

const isEligibleByElement = (eligibleByElements: { selector: string, value: string }[]) => {
	return eligibleByElements.every(({ selector, value }) => {
		const element = window.top.document.querySelector(selector);
		return value === "include" ? element : !element;
	});
}

async function setupConfigs(configs: Config[], publisherId: string) {
	if (window.ampSeen) {
		logConsole("AMP activated");
		document.getElementById("c").insertAdjacentHTML("beforeend", `<div id="mia-splitbox"></div>`);
	}
	const forcedTarget = document.getElementById("mia-splitbox");
	const params = new URLSearchParams(window.location.search);
	const miaParams = [...params].reduce((params, [key, value]) => {
		if (key.includes("mia_")) params.push(`&${key}=${value}`);
		return params;
	}, []).join('');
	const policies = await getPolicies(window);
	await userSync(policies);
	Object.values(configs).forEach((config: Config) => {
		config.miaParams = miaParams;
		config.tracking = (event: string) => tracking(event, publisherId, config.id);
		if (config.eligibleByElements.length > 0) {
			if (!isEligibleByElement(config.eligibleByElements)) {
				logConsole(`Not eligible config by eligibleByElements. Config ID: ${config.id}.`, ["eligibleByElements:", config.eligibleByElements]);
				config.tracking("excluded_by_element");
				return;
			} else {
				config.tracking("eligible_by_element");
			}
		}
		config.publisherId = publisherId;
		config.localization = LOCALIZATION[config.language || "en"];
		config.policies = policies;
		logConsole("Passed main config", ["Config ID:", config.id, "\nMain config:", config]);

		if (forcedTarget) {
			logConsole("Forced div activated. #mia-splitbox founded in markup", ["Config ID:", config.id, "\nMain config:", config])
			const [targetDiv] = getTargets({ id: config.id, type: "DIV_INSIDE" }, [forcedTarget]);
			setupTargets(config, [targetDiv]);
		} else {
			new AutoInsertion(config);
		}
	});
}

export default setupConfigs;