declare global { interface Window { springserveUserId: string; springserveRegion: string } }

function extractRKValue(input: string) {
  const pattern = /vid-io-([^/]+)\.springserve\.com/;
  const match = pattern.exec(input);
  return match ? match[1] : null;
}

export default async function userSync({ gdpr, gdpr_consent = "", uspString: us_privacy }) {
  const callback = async (data: string) => {
    const parsedData = data.match(/'user_id': '(.*)'/)[1];
    if (parsedData) {
      window.springserveUserId = parsedData;
    }
    const url = `https://tv.springserve.com/ssusersync?gdpr=${gdpr}&gdpr_consent=${gdpr_consent}&us_privacy=${us_privacy}`;
    const response = await fetch(url, { credentials: "include" });
    const userSyncData = await response.text();
    const region = extractRKValue(userSyncData);
    window.springserveRegion = region;
    const script = document.createElement("script");
    script.type = 'text/javascript';
    script.text = userSyncData;
    document.head.appendChild(script);
  };

  await fetch("https://sync.springserve.com/usersync/jsonp?callback=callback", { credentials: "include" })
    .then((response) => response.text())
    .then(callback)
    .catch((err) => console.error("Fetch Error", err));
}
