import setupConfigs from "@wrapper/setupConfigs";
import { buildPassbackIframe } from "@player/passbacks";
import { getTargets } from "@wrapper/insertion";
import { Bridge } from "@/types";

const fskAdsBridge: Bridge = {
  getSplitBoxDivId (insertionConfig) {
    return insertionConfig.allowMultiple
      ? `mia-outstream-${Math.random().toString(36).substring(2, 12)}` // 10 chars random id from 0-9 and a-z
      : `mia-outstream-${insertionConfig._spConfId}`
  },
  getInnerWidth (element) {
    if (element.tagName.toUpperCase() === "P") {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = getComputedStyle(element).font;

      return context.measureText(element.innerText).width;
    } else {
      return this.width(element);
    }
  },
  width (element) {
    return parseInt(window.getComputedStyle(element, null).width.replace("px", ""));
  },
  height (element) {
    return parseInt(window.getComputedStyle(element, null).height.replace("px", ""));
  },
  messageHandlers: [],
  addMessageHandler (event, func) {
    if (func === window._FskTGAMImpressionListener) {
      func = () => {
        if (event === "AdImpression") {
          document.getElementById("fsk-ad").classList.add("mia-opened");
        }
        document.querySelector(".fsk-ad-empty")?.remove();
      }
    }

    if (!this.messageHandlers[event]) {
      this.messageHandlers[event] = [];
    }
    this.messageHandlers[event].push(func);
  },

  registerMessageHandler (event, func) {
    if (Array.isArray(event)) {
      event.forEach(item => this.addMessageHandler(item, func));
    } else {
      this.addMessageHandler(event, func);
    }
  },
  mapEvents(events) {
    const map = {
      impression: "AdImpression",
      complete: "VideoCompleted",
      beforePlay: "PlayerReady",
      // MobileAdValid
      // VideoSize
    };
    Object.entries(map).forEach(([ playerEvent, customScriptsEvent ]) => {
      events.on(playerEvent, () => {
        this.messageHandlers[customScriptsEvent]?.forEach((callback) => {
          callback({
            ev: customScriptsEvent,
            // c: config._spConfId,
            // d: data
          });
        });
      })
    })
  },
  insertSplitBoxDiv: (insertionConfig, containers) => getTargets(insertionConfig, containers instanceof Array ? containers : containers.elements),
  appendStyle: (css) => document.querySelector("head").insertAdjacentHTML("beforeend", `<style>${css}</style>`),
  buildPassbackIframe: (container, width, height, doScale) => buildPassbackIframe(container, { width, height, doScale }, ""),
  scanSplitBox: () => setupConfigs([window.fskConfig], window._fskAdId),
}

export default fskAdsBridge;