const JW_EVENTS_MAPPING = {
  adStarted: "start",
  adImpression: "impression",
  // viewable: "viewable",
  adComplete: "complete",
  adClick: "clickthrough",
  // mute: "mute",
  adPause: "pause",
  adPlay: "play",
  beforePlay: "beforePlay",
  adTime: "time",
}

const eventDispatcher = {
  attachEvents(player: { jwplayer: any }) {
    this.flags = {};
    this.events = {};
    this.player = player;
    Object.entries(JW_EVENTS_MAPPING).forEach(([ event, dispatchableEvent ]) => {
      player.jwplayer.on(event, (params: string) => {
        if (this[`${dispatchableEvent}Handler`]) {
          this[`${dispatchableEvent}Handler`](params);
          return;
        }
        this.dispatch(dispatchableEvent, params);
      });
    });

    return this;
  },

  on(event: string, callback: () => void) {
    if (!this.events[event]) {
      this.events[event] = [callback]
    } else {
      this.events[event].push(callback);
    }
    return this;
  },
  dispatch(event: string, params: string) {
    this.events[event]?.forEach((callback: (params: string) => void) => {
      callback(params);
    });
  },

  timeHandler({ position, duration }) {
    this.player.positionTime = position.toFixed(1);
    if (!this.flags.firstQuartile && (position >= duration / 4)) {
      this.dispatch("firstquartile");
      this.flags.firstQuartile = true;
    }
    if (!this.flags.midpoint && (position >= duration / 2)) {
      this.dispatch("midpoint");
      this.flags.midpoint = true;
    }
    if (!this.flags.thirdQuartile && (position >= duration / 4 * 3)) {
      this.flags.thirdQuartile = true;
      this.dispatch("thirdquartile");
    }
  },
  viewableHandler({ viewable }) {
    if (!this.flags.viewable && viewable) {
      this.flags.viewable = true;
      this.dispatch("firstViewable");
    }
    this.dispatch("viewable", viewable);
  },
  muteHandler({ mute }) {
    if (!this.flags.unmuted) {
      this.flags.unmuted = true;
      this.dispatch("firstUnmute");
    }
    this.dispatch(mute ? "mute" : "unmute");
  },
  playHandler({ oldstate }) {
    this.dispatch("play");
    if (oldstate === "buffering") return;
    this.dispatch("resume");
  },
}

export default eventDispatcher;