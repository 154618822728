import gdpr from './index';

let gdprCB = () => {
  // callback to call when got GDPR data
};
let callId = 0;

function cmpHandler(cmpResult, success) {
  let gdprStatus = {};
  if (!cmpResult || success === false) {
    gdprStatus = {
      gdpr: 0
    };
  } else {
    gdprStatus.gdpr = cmpResult.gdprApplies ? 1 : 0;
    if (cmpResult.consentData) {
      gdprStatus.gdpr_consent = cmpResult.consentData;
    }
  }
  gdpr.setConsentData(gdprStatus, 1);
  gdprCB();
}

window.addEventListener('message', (event) => {
  if (event && event.data && event.data.__cmpReturn) {
    cmpHandler(
      event.data.__cmpReturn.returnValue,
      event.data.__cmpReturn.success
    );
  }
}, false);

const tcfv1 = {
  createConsentPromise(iframeWindow: Window) {
    // GDPR - call Consent Manager Provider API
    return new Promise((resolve) => {
      let isCalled = false;
      gdprCB = () => {
        isCalled = true;
        resolve(true);
      };
      this.getConsentData();
      setTimeout(() => {
        if (!isCalled) {
          isCalled = true;
          gdprCB();
        }
      }, iframeWindow?.miaDelayCMP || 100);
    });
  },

  registerCb(cb) {
    gdprCB = cb;
  },

  getConsentData() {
    if (window.__cmp) {
      // same frame
      window.__cmp('getConsentData', null, cmpHandler);
    } else {
      // iframe
      callId += 1;
      window.top.postMessage({
        __cmpCall: {
          callId: `iframe:${callId}`,
          command: 'getConsentData'
        }
      }, '*');
    }
  }
}

export default tcfv1;