import { DEFAULT_PLAYER_URL } from "@/constants";
import { Config } from "@/types";
import { loadScript, logError } from "@/utils";
import handlePassback from "@player/passbacks";
import setupPlayer from "@player/setupPlayer";

export default async function initAd(config: Config, iframeWindow: Window, id: string, container: HTMLElement) {
  iframeWindow.miaLib = window.miaLib;
  config.container = container;
  iframeWindow.fskConfig = config;
  iframeWindow._fskPlacementId = id;
  iframeWindow._fskAdId = container.id;

  if (config.customScript) {
    const customScript = document.createElement("script");
    customScript.innerHTML = config.customScript;
    iframeWindow.document.body.appendChild(customScript);
  }

  try {
    await loadScript(DEFAULT_PLAYER_URL, iframeWindow);
    await setupPlayer(config, iframeWindow);
  } catch (error) {
    logError(error, config);
    handlePassback(config, config.container);
  }
}
